import axiosGlobal from '../../../axios-auth'
// eslint-disable-next-line import/no-cycle
import router from '../../router/index'

export default {
  namespaced: true,
  state: {
    idToken: null,
    userId: null,
    user: null,
    displayName: null,
  },
  getters: {
    user(state) {
      return state.user
    },
    isAuthenticated(state) {
      return state.idToken !== null
    },
  },
  mutations: {
    authUser(state, userData) {
      state.idToken = userData.token
      state.userId = userData.userId
      state.displayName = userData.userName
    },
    storeUser(state, user) {
      state.user = user
    },
    clearAuthData(state) {
      state.idToken = null
      state.userId = null
    },
  },
  actions: {
    async login({ commit }, authData) {
      await axiosGlobal.post('/api/login', {
        cpf: authData.cpf,
        password: authData.password,
      })
        // eslint-disable-next-line consistent-return
        .then(async res => {
          commit('authUser', {
            token: res.data.access_token,
            userId: res.data.user.id,
            userName: res.data.user.name,
          })
          const now = new Date()
          const expirationDate = now.getTime() + 3600 * 1000
          localStorage.setItem('token', res.data.access_token)
          localStorage.setItem('userId', res.data.user.id)
          localStorage.setItem('funcionarioId', res.data.user.funcinarioId)
          localStorage.setItem('meta', res.data.user.meta)
          localStorage.setItem('usuarioEmpresas', res.data.user.usuario_empresas)
          localStorage.setItem('userName', res.data.user.name)
          localStorage.setItem('cpf', res.data.user.cpf)
          localStorage.setItem('userEmail', res.data.user.email)
          // localStorage.setItem('acesso_id', '1')
          // localStorage.setItem('credor', res.data.credor)
          localStorage.setItem('funcao', res.data.user.funcao)
          localStorage.setItem('expirationDate', expirationDate)
          localStorage.setItem('userData', JSON.stringify(res.data.user))
          if (res.data.credor) {
            localStorage.setItem('credores', JSON.stringify(res.data.credor))
          }
          if (res.data.user.executivo_cobranca && res.data.user.funcao !== 'Supervisor(a) Cobrança') {
            const executivoRes = await axiosGlobal.get(`api/v1/executivo/findId/${res.data.user.executivo_cobranca}`, {
              headers: {
                Authorization: 'Bearer '.concat(res.data.access_token),
                accept: 'application/json',
              },
            })
            // Campo duplicado no localStorage com executivo_id??
            localStorage.setItem('executivo_id', res.data.user.executivo_cobranca)
            localStorage.setItem('discador.user', executivoRes.data.dados.usuario)
            localStorage.setItem('discador.password', executivoRes.data.dados.senha)
            localStorage.setItem('discador.base_url', 'http://192.168.254.253/api/toolbar/')
            localStorage.setItem('discador.ramal', executivoRes.data.dados.ramal)
          }

          let permissõesGrupo = []
          const permissoesUser = {}
          res.data.permissoes.map(item => {
            permissõesGrupo.push(item.grupo)
          })
          permissõesGrupo = [...new Set(permissõesGrupo)]
          permissõesGrupo.map(key => {
            res.data.permissoes.map(item => {
              if (item.grupo === key) {
                if (!permissoesUser[key]) {
                  permissoesUser[key] = {}
                }
                permissoesUser[key][item.nome] = {
                  permissao: item.permissao,
                  url: item.url,
                }
              }
            })
          })
          localStorage.setItem('lastCalls', JSON.stringify({}))
          localStorage.setItem('permissoes', JSON.stringify(permissoesUser))
          // dispatch('setLogoutTime', 3600)
          router.go()
        })
    },
    async logout() {
      const logoutDiscador = async () => {
        if (localStorage.getItem('acesso_id') === '5') {
          await axiosGlobal.get(localStorage.getItem('discador.base_url'), {
            params: {
              comando: 'SAIR',
              json: true,
              operador: 'tiringa',
            },
          })
        }
      }
      await logoutDiscador()
      localStorage.clear()
      router.replace('/')
    },
    tryAutoLogin({ commit }) {
      const token1 = localStorage.getItem('token')
      if (!token1) {
        return
      }
      const expirationDate = localStorage.getItem('expirationDate')
      const now = new Date()
      if (now >= expirationDate) {
        return
      }
      const userId1 = localStorage.getItem('userId')
      const userName1 = localStorage.getItem('userName')
      commit('authUser', {
        token: token1,
        userId: userId1,
        userName: userName1,
      })
    },
  },
}
