import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Vue from 'vue'

Vue.mixin({
  methods: {
    getInitials(name) {
      let initials = name.split(' ')
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0)
      } else {
        initials = name.substring(0, 2)
      }
      return initials.toUpperCase()
    },
  },
  dataHora(dados, op = false) {
    const a = dados
    const b = a.split('T')
    const c = b[0].split('-')
    const d = b[1].split('.')
    const e = d[0].split(':')
    const dataBr = `${c[2]}/${c[1]}/${c[0]}`
    const horaBr = `${e[0]}:${c[1]}`
    const dataHora = `${dataBr} ${horaBr}`
    if (op) {
      return dataBr
    }
    return dataHora
  },

})

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.mixin({
  methods: {
    cpfLabel(cpf) {
      cpf = cpf.replace(/[^\d]/g, '')

      // realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    },
    phoneLabel(number) {
      let phone = number.replace(/\D/g, '')
      const match = phone.match(/^(\d{1,2})(\d{0,5})(\d{0,4})$/)
      if (match) {
        phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}${match[4] ? '' : ''}`
      }
      return phone
    },
    fisrtLastName(fullName) {
      const firstName = fullName.split(' ')[0]
      const lastName = fullName.split(' ').slice(-1)
      return (`${firstName} ${lastName}`)
    },
    firstAndSecondName(fullName) {
      const firstName = fullName.split(' ')[0]
      const secondName = fullName.split(' ')[1]
      return (`${firstName} ${secondName}`)
    },
    getInitials(name) {
      let initials = name.split(' ')
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0)
      } else {
        initials = name.substring(0, 2)
      }
      return initials.toUpperCase()
    },
    formatTimezone(dados) {
      const a = dados.split(' ')
      const b = a[0].split('-')
      const dataBr = `${b[2]}/${b[1]}/${b[0]}`
      return dataBr
    },
    dataHora(dados, op = false) {
      const a = dados
      const b = a.split('T')
      const c = b[0].split('-')
      const d = b[1].split('.')
      const e = d[0].split(':')
      const dataBr = `${c[2]}/${c[1]}/${c[0]}`
      const horaBr = `${e[0]}:${e[1]}`
      const dataHora = `${dataBr} ${horaBr}`
      if (op) {
        return dataBr
      }
      return dataHora
    },
    cpfCNPJ(dados) {
      const cpfCNPJ = dados.replace(/\D/g, '')
      let a = null
      if (cpfCNPJ.length === 11) {
        a = cpfCNPJ.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
      } else {
        a = cpfCNPJ.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
      }
      return a
    },
    valorBr(dados, op = false) {
      parseFloat(dados)
      if (op) {
        const valor = dados.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        return valor
      }
      const valor = dados.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      return valor
    },
    randomColor(id) {
      const r = () => Math.floor(256 * Math.random())

      // eslint-disable-next-line no-return-assign
      return this.colorCache[id] || (this.colorCache[id] = `rgb(${r()}, ${r()}, ${r()})`)
    },
    linhaDigitavel(ld) {
      const list = ld
      return list.replace(/(\d{5})(\d{5})(\d{5})(\d{5})(\d{5})(\d{5})(\d{1})(\d{14})/g, '$1.$2 $3.$4 $5.$6 $7 $8')
    },
    americaDate(dados) {
      const item = dados.split(' ')[0]
      const list = item.split('-')
      if (list.length === 3) {
        const response = `${list[2]}/${parseInt(list[1]) < 10 ? `0${parseInt(list[1])}` : list[1]}/${list[0]}`
        return response
      }
      return item
    },
    removerAcentos(text) {
      text = text.toLowerCase()
      text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
      text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
      text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
      text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
      text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
      text = text.replace(new RegExp('[Ç]', 'gi'), 'c')
      return text.toUpperCase()
    },
    badgeVariantPerfilCliente(perfil) {
      const colors = {
        Ocasional: 'light-success',
        Crônico: 'light-primary',
        Negligente: 'light-warning',
        'Mau Pagador': 'light-danger',
      }
      return colors[perfil] || 'light-secondary'
    },
  },
})
