import api from '@/../axios-auth'

const urlConfig = 'http://192.168.254.253/api/toolbar/'

const getExec = async () => {
  const { executivo_id: idExecutivo } = localStorage
  const findExec = await api.get(`api/v1/executivo/findId/${idExecutivo}`, {
    headers: {
      Authorization: 'Bearer '.concat(localStorage.getItem('token')),
      Accept: 'application/json',
    },
  }).then(res => res.data.dados)

  // const { ramal, senha, usuario } = findExec
  return findExec
}

// eslint-disable-next-line import/prefer-default-export
export const ping = async () => {
  const { usuario } = await getExec()
  const response = await api.get(urlConfig, {
    params: {
      comando: 'PING',
      json: true,
      operador: usuario,
    },
  }).then(res => {
    const {
      call_id,
      status_complemento,
      telefone,
      cpf,
    } = res.data
    const lastCalls = JSON.parse(localStorage.getItem('lastCalls'))
    lastCalls[cpf] = {
      cpf,
      telefone,
      call_id,
      origem: status_complemento,
    }
    localStorage.setItem('lastCalls', JSON.stringify(lastCalls))
    return res.data
  }).catch(() => ({
    call_id: null,
    status_complemento: null,
    telefone: null,
    cpf: null,
  }))
  return response
}
